.articles {

}

.articles__section-container {
  padding-top: 50px;
  padding-bottom: 50px;
}


.articles__list {
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: relative;
  margin-top: 100px;
}

.articles__add-link {
  position: absolute;
  top: -80px;
  right: 0;
  padding: 20px;
  background-color: #002c49;
  border-radius: 8px;
  text-decoration: none;
  color: #c5c5c5;
}

.article__confirm-delete .popup__container {
  gap: 30px;
}

.article__confirm-delete-buttons-container {
  display: flex;
  gap: 20px;
}

.article__confirm-delete-button {
  padding: 10px 30px;
  border-radius: 8px;
  border: none;
  color: #fff;
  cursor: pointer;
}

.article__confirm-delete-button_positive {
  background: #088308;
}

.article__confirm-delete-button_negative {
  background: #830808;
}

