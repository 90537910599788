.article-editor {

}

.article-editor__form-container {
  width: 1000px;
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 50px;
}

.article-editor__form {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  border-radius: 8px;
  margin-top: 50px;
}

#article-editor-form .form__field_big {
  width: 100%;
}

#article-editor-form .form__field_small {
  width: 450px;
}

.article-editor__button-container {
  display: flex;
  gap: 30px;
  align-items: center;
  margin-top: 30px;
}

.article-editor__form-reset-button {
  color: #fc653c;
  background-color: #ffffff;
  border: 2px #fc653c solid;
}

.article-editor__form-reset-button:hover.article-editor__form-reset-button  {
  color: #fff;
}

.article-editor__file-label {
  width: 450px;
  box-sizing: border-box;
}

.article-editor__file-label::after {
  content: url('../../assets/images/icons/upload.svg');
  width: 50px;
  height: 50px;
  background-color: #F9F9F9;
  padding: 15px 200px;
  border-radius: 8px;
  margin-top: 15px;
  opacity: 0.8;
}

.article-editor__image-field {
  width: 450px;
}

.article-editor__field-with-image {
  justify-content: space-between;
}

.visible {
  display: flex;
  flex-direction: row;
}