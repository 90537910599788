.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px 0;
}

.login__container {
  width: 396px;
}

.login__header {

}

.login__logo {
  width: 250px;
}

.login__title {
  font-family: 'Inter', 'Arial', 'Helvetica', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  text-align: left;
  vertical-align: top;
  color: #000;
  margin: 40px auto;
}

.login__form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.login__field {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  gap: 10px;
}


.login__label {
  font-family: 'Inter', 'Arial', 'Helvetica', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: left;
  vertical-align: top;
  color: #A0A0A0;
  margin-bottom: 10px;
}

.login__input {
  background-color: #F9F9F9;
  border: none;
  border-radius: 8px;
  padding: 15px;
  font-family: 'Inter', 'Arial', 'Helvetica', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: left;
  vertical-align: top;
  color: #000;
}

.login__input_email {
  font-weight: 500;
}

.login__input_invalid {
  color: #FF3055;
}

.login__submit-button {
  width: 100%;
  border: none;
  border-radius: 3px;
  padding: 13px 0 15px;
  margin: 159px auto 16px;
  font-family: 'Inter', 'Arial', 'Helvetica', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  vertical-align: top;
  color: #FFFFFF;
  background-color: #3456F3;
  cursor: pointer;
}

.login__submit-button:disabled {
  opacity: 0.1;
  cursor: not-allowed;
}

.login__entry {
  display: flex;
  justify-content: center;
  gap: 6px;
}

.login__paragraph {
  font-family: 'Inter', 'Arial', 'Helvetica', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  vertical-align: top;
  color: #A0A0A0;
}

.login__link {
  font-family: 'Inter', 'Arial', 'Helvetica', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  vertical-align: top;
  color: #3456F3;
  text-decoration: none;
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
  .login {
    padding: 232px 0;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .login {
    padding: 56px 0 30px;
  }
  .login__container {
    width: 260px;
  }

  .login__header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .login__title {
    width: 260px;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 80px;
  }

  .login__submit-button {
    margin: 115px auto 16px;
  }

  .login__paragraph {
    font-size: 12px;
    line-height: 15px;
  }

  .login__link {
    font-size: 12px;
    line-height: 15px;
  }
}