.form {
  width: 100%;
  padding: 40px;
  margin: 0 auto;
  box-sizing: border-box;
  -webkit-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
}

.form__field {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
}

.form__label {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: left;
  vertical-align: top;
  color: #A0A0A0;
}

.form__input {
  background-color: #F9F9F9;
  border: none;
  border-radius: 8px;
  padding: 15px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: left;
  vertical-align: top;
  color: #000;
  box-sizing: border-box;
  width: 100%;
}

.form__textarea {
  background-color: #F9F9F9;
  border: none;
  border-radius: 8px;
  padding: 15px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: left;
  vertical-align: top;
  color: #000;
  box-sizing: border-box;
  width: 100%;
  min-height: 150px;
}

/* Делаем красивый label загрузки файла 
   Задаем дополнительные стиль в модуле, с указанием доп класса по БЭМ
*/

.form__file-label {
  display: flex;
  flex-direction: column;
}

.form__file-label::after {
  display: inline-block;
  cursor: pointer;
}

.form__field .form__file-input {
  display: none;
}

/****************************************/

/* .form__field_big, .form__field_small указываются для каждого проекта свои, желательно через id. */