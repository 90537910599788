.footer {
  width: 100%;
  background-color: #002c49;
  padding: 20px;
  box-sizing: border-box;
}

.footer__container {
  width: 100%;
  max-width: 1240px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.footer__logo {
  max-width: 250px;
}