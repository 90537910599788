
.section__button {
  background: #fc653c;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 28px;
  overflow: hidden;
  padding: 10px 22px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  transition: .5s;
}

.section__button:disabled {
  cursor: not-allowed;
  opacity: .5;
}

.section__button_transparent {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat', 'Arial', 'Helvetica', sans-serif;
  line-height: 18px;
  font-size: 16px;
  padding: 12px 20px;
  border-radius: 5px;
  color: #fff;
  background-color: transparent;
  border: 2px #fff solid;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  transition: 0.15s;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
}

.section__button_transparent::after {
  width: 100%;
  height: 100%;
  content: '';
  background-color: #fc653c;
  display: inline-block;
  position: absolute;
  transform: scaleX(0);
  transition: 0.75s;
  transform-origin: 0 50%;
  z-index: -1;
}

.section__button_transparent:hover.section__button_transparent::after {
  transform: scaleX(1);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}