.profile {
  margin: 120px auto;
}

.profile__container {
  width: 680px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  margin: 60px auto;
  padding: 40px;
  box-sizing: border-box;
  box-shadow: 0px 5px 10px 2px rgb(34 60 80 / 20%);
}

.profile__name {
}

.profile__info-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 30px;
  width: 600px;
  margin: 0 auto;
  border-radius: 8px;
  border: 1px solid #ececec;
  padding: 40px;
  box-sizing: border-box;
  /* -webkit-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2); */
}

.profile__item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  gap: 5px;
}

.profile__paragraph {
  color: #6e6e6e;
  font-weight: 500;
  white-space: nowrap;
}

.profile__info-value {
  font-weight: 800;
  white-space: nowrap;
  color: #272727;
}

.profile__item-line {
  width: 100%;
  height: 0px;
  border-bottom: 1px dashed #6e6e6e;
}

.profile__info-button {

}

.profile__info-button-container {
  width: 600px;
  margin: 0 auto;
  text-align: end;
}