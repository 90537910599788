.info-message {
  width: 300px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 10px;
  right: 10px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.5s, opacity 0.5s linear;
  padding: 26px;
  box-sizing: border-box;
}

.info-message__text {
  font-size: 18px;
  color: #fff;
  line-height: 24px;
}

.info-message__close-button {
  width: 16px;
  height: 16px;
  background-image: url('../../assets/images/form_close.svg');
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: transparent;
  border: none;
}

.info-message_show {
  visibility: visible;
  opacity: 1;
}

.info-message_positive {
  background-color: #245026;
}

.info-message_negative {
  background-color: #860000;
}
