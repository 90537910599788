.error-message {
  font-family: 'Inter', 'Arial', 'Helvetica', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: left;
  vertical-align: top;
  color: #FF3055;
  display: none;
}

.error_visible {
  display: block;
}

.error__field {
  color: #FF3055;
}

.error__field:focus {
  outline: 2px solid #FF3055;
}