
* {
  font-family: 'Montserrat', 'Arial', 'Helvetica', sans-serif;
}

.ck-editor__field {
  width: 100%;
}

.section {
  width: 100%;
}

/* В каждом проекте своя ширина контейнера секции */

.section__container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.section__container_full-width {
  max-width: 100%;
}


.ck-editor__field p {
  margin-top: 24px;
}

.ck-editor__field p:first-child {
  margin-top: 0;
}

.section__header {
  display: flex;
  align-items: center;
  gap: 10px;
}

.header__count {
  font-size: 20px;
  padding: 5px 15px;
  background: #9e9e9e;
  border-radius: 30px;
  color: white;
}