.popup {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
  display: flex;
  transition: visibility 0.5s, opacity 0.5s linear;
}

.popup__title {
  font-size: 18px;
  font-weight: 700;
  margin: 0;
}

.popup_opened {
  visibility: visible;
  opacity: 1;
}

.popup__close-button {
  width: 32px;
  height: 32px;
  background-image: url('../../assets/images/form_close.svg');
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  right: 40px;
  top: 40px;
  background-color: transparent;
  border: none;
 }

 .popup__close-button:hover {
   cursor: pointer;
   opacity: 0.6;
 }

 .popup__container {
  display: flex;
  width: 430px;
  background-color: #fff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  flex-direction: column;
  position: relative;
  align-items: center;
  padding: 40px 20px;
 }