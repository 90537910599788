.article-card {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  padding: 20px 40px;
  box-sizing: border-box;
  border: 1px solid rgb(0 0 0 / 13%);
}

.article-card:hover {
  -webkit-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
}

.article-card_current {
  width: 100%;
  height: 50px;
  background-color: aliceblue;
}

.article-card__info-container {
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: center;
}

.article-card__image {
  height: 100px;
}

.article-card__button-container {
  display: flex;
  gap: 20px;
}

.article-card__button {
  background: transparent;
  border: none;
  cursor: pointer;
}

