.header {
  width: 100%;
  background-color: #002c49;
  padding: 20px;
  box-sizing: border-box;
}

.header__container {
  width: 100%;
  max-width: 1240px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}

.header__paragraph {
  color: #c5c5c5;
}

.header__logo {
  max-width: 250px;
}

.header__nav {
  display: flex;
  gap: 15px;
}

.header__link {
  text-decoration: none;
  color: #f4f4f4;
  font-weight: 600;
}

.header__link_active {
  text-decoration: underline;
}

.header__link_profile {
  width: 24px;
  height: 24px;
  position: relative;
}
.header__link_profile::after {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url('../../assets/images/icons/profile.svg');
  background-position: center;
  background-size: cover;
  position: relative;
  right: 0;
  top: 1px;
  margin-left: 5px;
}
.header__profile-container {

}